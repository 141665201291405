





















import { defineComponent, onMounted, reactive } from "@vue/composition-api"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { projectsContainer } from "@/containers/ProjectsContainer"
import TextButton from "@/components/buttons/TextButton.vue"
import MyMeetingSeries, { TeamMeetingStructure } from "./MyMeetingSeries.vue"
import { Agenda, Meeting } from "@/models/Meeting"
import { meetingContainer } from "@/containers/MeetingsContainer"
import DateTime from "@/models/DateTime"
import { Event } from "@microsoft/microsoft-graph-types"
import { uniqueArray } from "@/utilities/UniqueArray"
import User from "@/models/User"

interface State {
  loading: boolean
  selected: TeamMeetingStructure | null
  event: Event | null
  purpose: string | null
  goal: string | null
  agendas: Agenda[] | null
  priorityIds: Array<string>
  displayStructureId: string
}

export default defineComponent({
  components: {
    TextButton,
    MyMeetingSeries,
  },
  setup() {
    const { getMembers } = teamsContextContainer.useContainer()
    const { getProjectAsync } = projectsContainer.useContainer()
    const { createMeetingAsync } = meetingContainer.useContainer()
    let source: MessageEventSource | null =
      window.parent || (window.opener !== window ? window.opener : null)
    let origin: string | null =
      window.parent || window.opener !== window ? "*" : null
    const state = reactive<State>({
      loading: false,
      selected: null,
      event: null,
      purpose: null,
      goal: null,
      agendas: null,
      priorityIds: [],
      displayStructureId: "",
    })

    const isValidEventData = (evt: Event) => {
      return (
        evt.iCalUId &&
        (evt.isOnlineMeeting === true || evt.isOnlineMeeting === false) &&
        evt.start &&
        evt.start.dateTime &&
        evt.end &&
        evt.end.dateTime
      )
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    const postMessage = (data: Object) => {
      if (
        !!source &&
        !!origin &&
        !(source instanceof MessagePort) &&
        !(source instanceof ServiceWorker)
      )
        source.postMessage(JSON.stringify(data), origin)
    }

    const createMeeting = async () => {
      const event = state.event
      const purpose = state.purpose || ""
      const goal = state.goal || ""
      const agendas = state.agendas || []
      if (
        !state.selected ||
        !state.selected?.project?.groupId ||
        !state.selected?.project?.sharepointListId ||
        !event
      )
        return
      state.loading = true
      try {
        const groupId = state.selected.project.groupId
        const channelId = state.selected.project.channelId
        const entityId = state.selected.project.sharepointListId
        const structureId = state.selected.id
        const members = await getMembers(groupId, channelId)
        const attendeesAddress = uniqueArray(
          u => {
            if (!u || !u.address) {
              return ""
            } else {
              return u.address
            }
          },
          event.attendees?.map(u => u.emailAddress) ?? [],
          [event.organizer?.emailAddress] ?? []
        )
        const meeting = new Meeting({
          iCaluid: event.iCalUId,
          isTeams: event.isOnlineMeeting,
          subject: event.subject ?? "(件名なし)",
          location: event.location?.displayName,
          startTime: new DateTime(
            event.start?.dateTime,
            event.start?.timeZone || undefined
          ),
          endTime: new DateTime(
            event.end?.dateTime,
            event.end?.timeZone || undefined
          ),
          users: attendeesAddress?.map(a => {
            const user = members.find(u => u.email === a?.address)
            return new User({
              id: user?.id,
              displayName: a?.name,
              email: a?.address,
              userId: user?.userId,
            })
          }),
          purpose,
          goal,
          agendas: agendas?.filter(a => a.agenda) ?? [],
        } as Meeting)
        const project = await getProjectAsync(entityId)
        await createMeetingAsync(
          project.siteId,
          entityId,
          structureId,
          meeting,
          false
        )
        postMessage({
          state: "success",
          selected: {
            id: state.selected.id,
            teamName: state.selected.teamName,
            channelName: state.selected.channelName,
            name: state.selected.name,
          },
        })
      } catch (e) {
        postMessage({
          state: "fail",
          cose: -3,
          message: e,
        })
      } finally {
        state.loading = false
      }
    }

    window.addEventListener(
      "message",
      event => {
        if (!event.source || !event.origin) return
        source = event.source
        origin = event.origin
        try {
          const currentData = JSON.parse(event.data)
          if (currentData.event) {
            const newEvent = currentData as {
              event: Event
              purpose?: string
              goal?: string
              agendas?: Agenda[]
              priorityIds?: string[]
            }
            if (!isValidEventData(newEvent.event)) {
              postMessage({
                state: "fail",
                cose: -1,
                message: "event is invalid",
              })
              return
            }
            state.event = newEvent.event
            state.priorityIds = newEvent.priorityIds || []
            state.purpose = newEvent.purpose || null
            state.goal = newEvent.goal || null
            state.agendas = newEvent.agendas || null
            state.displayStructureId = ""
          } else {
            state.event = null
            state.priorityIds = []
            state.purpose = null
            state.goal = null
            state.agendas = null
            state.displayStructureId = currentData.showId
          }
        } catch (e) {
          postMessage({
            state: "fail",
            cose: -2,
            message: e,
          })
          return
        }
      },
      false
    )

    onMounted(() => {
      postMessage({
        state: "ready",
      })
    })

    return {
      state,
      createMeeting,
    }
  },
})
